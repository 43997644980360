import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import UserSelector from '../../commonComponents/forms/inputs/UserSelector/UserSelector';
import userProp from '../../commonComponents/propTypes/userProp';
import UserTypeNamesProp from '../../commonComponents/propTypes/userTypeNamesProp';

const UserSelectorHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { multiple, initialValue, hint } = childProps;
  const defaultInitialValue = multiple ? [] : '';

  const [value, setValue] = useState(initialValue || defaultInitialValue);
  useEffect(() => propagateChanges(value), [value]);

  const handleSingleChange = id => setValue(id);
  const handleMultiChange = id => setValue(prev => prev.concat(id));
  const handleUnselect = id => setValue(prev => prev.filter(v => v !== id));

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    setValue: v => setValue(v),
  }));

  const userTypeNames = {};
  Object.values(userProp.TYPES).forEach(userType => {
    userTypeNames[userType] = i18n.__(`user_type_${userType}`);
  });

  return (
    <>
      <UserSelector
        {...childProps}
        value={value}
        onChange={multiple ? handleMultiChange : handleSingleChange}
        onUnselect={multiple ? handleUnselect : undefined}
        userTypeNames={userTypeNames}
      />
      {hint && <span className="m-form__help">{hint}</span>}
    </>
  );
});

UserSelectorHandler.propTypes = {
  multiple: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hint: PropTypes.string,
  propagateChanges: PropTypes.func.isRequired,
};

UserSelectorHandler.defaultProps = {
  multiple: false,
  initialValue: null,
  hint: null,
};

window.UserSelector = class UserSelectorRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'UserSelector container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <UserSelectorHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
